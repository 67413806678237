import { Refresh } from "@mui/icons-material";
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import HistorySelector from "./HistorySelector";
import { ChartTime } from "./SensorReadingsTable";
import { Sensor } from "../../generated/graphql";

type Props = {
  sensors?: Sensor[];
  onRefresh: () => void;
  timeData: ChartTime;
  onTimeChange: (timeData: ChartTime) => void;
};

export default function FullScreenSensor({
  onRefresh,
  timeData,
  onTimeChange,
}: Props) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const iconButtons = (
    <Box>
      <IconButton color="inherit" onClick={onRefresh}>
        <Refresh />
      </IconButton>
    </Box>
  );

  let buttons = (
    <Grid item container sm={8} display="flex" justifyContent="flex-end">
      <Grid item>
        <HistorySelector value={timeData} onChange={onTimeChange} />
      </Grid>
      <Grid item>{iconButtons}</Grid>
    </Grid>
  );

  if (smallScreen) {
    buttons = (
      <Grid container item xs={12} spacing={1} display="flex">
        <Grid item flex={1}>
          <HistorySelector value={timeData} onChange={onTimeChange} />
        </Grid>
        <Grid item>{iconButtons}</Grid>
      </Grid>
    );
  }

  return (
    <Box mb={2}>
      <Grid flex={1} display="flex" container spacing={2}>
        <Grid item xs={12} sm={4}></Grid>
        {buttons}
      </Grid>
    </Box>
  );
}
